<template>
  <b-modal id="list" hide-footer scrollable :title=" form.id === null ? 'Crear nueva categoría' : 'Editar categoría'">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <iq-card>
          <template v-slot:body>
            <div class="new-user-info">
              <b-row class="justify-content-md-center">
                <b-form-group>
                  <div class="add-img-user profile-img-edit text-center">
                    <b-img
                      class="height-180 width-180"
                      fluid
                      center
                      thumbnail
                      :src="preview"
                      alt="image"
                    />
                    <input type="hidden" />
                    <div class="p-image">
                      <b-button
                        variant="none"
                        class="upload-button iq-bg-primary position-relative"
                      >
                        <input
                          type="file"
                          @change="previewImage"
                          class="h-100 position-absolute btn-block"
                          accept="image/*"
                          style="opacity: 0"
                        />
                        CARGAR IMAGEN
                      </b-button>
                    </div>
                  </div>
                </b-form-group>
                <b-form-group class="col-md-12" label="Nombre:" label-for="sku">
                  <ValidationProvider
                    name="Nombre"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="form.name"
                      type="text"
                      placeholder="Nombre de Categoría"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                    </b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-row>
              <hr />
              <b-button variant="primary" block type="submit">Guardar</b-button>
            </div>
          </template>
        </iq-card>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  name: 'Comentaries',
  created () {
    this.comentaries = ''
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      form: {
        id: null,
        image: null,
        name: null
      },
      default: require('../../../assets/images/default.png'),
      preview: null
    }
  },
  methods: {
    async show (item) {
      this.form = {
        id: null,
        image: null,
        name: null
      }
      if (item) {
        this.preview = (item.image === null || item.image === '') ? this.default : item.image
        this.form = {
          id: item.id,
          name: item.name
        }
      } else {
        this.preview = this.default
        this.form = {
          id: null,
          image: null,
          name: null
        }
      }
      this.$bvModal.show('list')
    },

    async onSubmit () {
        try {
          this.$store.commit('run')
         const res =  await this.$store.dispatch(
            'category/storeCategory',
            this.form
          )
          this.$bvModal.hide('list')
          this.form = {
            id: null,
            image: null,
            name: null
          }
          this.preview = this.default
          this.$emit('paginate')
          this.$store.commit('stop')
          core.showSnackbar('success', res)
        } catch (error) {
          console.log(error)
          this.$store.commit('stop')
          const errors = error.response.data.errors
          for (const key in errors) {
            core.showSnackbar('error', errors[key][0])
            return false
          }
        }
    },
    previewImage: function (event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        this.form.image = input.files[0]
        reader.onload = (e) => {
          this.preview = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    }
  }
}
</script>
