<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Listado de Categorías</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="$refs.modal.show()"
              >Agregar Nueva
            </b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="6" class="my-3">
                <b-form-group
                  label="Buscar:"
                  label-for="filter-input"
                  class="mb-0"
                >
                  <b-input-group>
                    <b-form-input
                      id="filter-input"
                      :disabled="
                        !Array.isArray(getCategory) ||
                        (getCategory.length === 0 && isBusy === false)
                      "
                      v-model="filter"
                      type="search"
                      placeholder="Buscar categorías"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="12" class="table-responsive">
                <b-table
                  hover
                  :items="getCategory"
                  :busy="
                    !Array.isArray(getCategory) || getCategory.length === 0
                      ? true
                      : false || isBusy == true
                  "
                  :fields="columns"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  @filtered="onFiltered"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="isBusy">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong> Cargando datos...</strong>
                      </div>
                      <span class="h5" v-else>Sin registros</span>
                    </div>
                  </template>
                  <template v-slot:cell(image)="data">
                    <b-avatar
                      md="5"
                      class="ml-3 mr-0"
                      size="3rem"
                      variant="primary"
                      :src="data.item.image"
                    ></b-avatar>
                  </template>
                  <template v-slot:cell(name)="data">
                    <span>{{ data.item.name }}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                    v-b-tooltip.hover
                        title="Editar"
                    variant=" iq-bg-warning mr-1" 
                    size="sm" 
                    @click="$refs.modal.show(data.item)">
                      <i class="ri-ball-pen-fill m-0"></i>
                    </b-button>

                    <b-button
                    v-b-tooltip.hover
                        title="Eliminar"
                    variant=" iq-bg-danger" 
                    size="sm"
                    @click="showMsgBoxTwo(data.item)">
                      <i class="ri-delete-bin-line m-0"></i>
                    </b-button>
                  </template>
                </b-table>
                <b-pagination
                  class="mt-3"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  pills
                  size="sm"
                  align="center"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <modal ref="modal" v-on:paginate="paginate"></modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import modal from '@/components/core/modals/CategoryModals'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ListCategory',
  components: {
    modal
  },
  async created () {
    this.$store.commit("SET_BREADCRUMB", this.items);
    this.isBusy = true
    await this.listCategory()
    this.isBusy = false
    this.totalRows = this.getCategory.length
  },
    mounted () {
      core.index()
    },
  methods: {
    ...mapActions({
      listCategory : 'category/listCategory',
      destroyCategory: 'category/destroyCategory'
    }),
    paginate(){
      this.totalRows = this.getCategory.length
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async remove (item) {
      try {
        await this.destroyCategory(item.id)
        this.totalRows = this.getCategory.length
        core.showSnackbar('success', 'Registro eliminado correctamente')
      } catch (error) {
        console.log(error)
      }
    },
     showMsgBoxTwo(item) {
        this.boxTwo = ''
        this.$bvModal.msgBoxConfirm('¿Desea eliminar este registro?.', {
          title: 'Confirmación',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Si',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
         .then(value => {
            value ? this.remove(item) : ''
          })
          .catch(err => {
            console.log(err)
          })
      }
  },
  computed: {
    ...mapGetters({
      getCategory : 'category/getCategory'
    })
  },
  data () {
    return {
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1'
      },
      columns: [
        { label: 'Imagen', key: 'image', class: 'text-center' },
        { label: 'Nombre', key: 'name', class: 'text-center' },
        { label: 'Acciones', key: 'action', class: 'text-center' }
      ],
      rows: [],
      items: [
        {
          html: 'Inicio',
          to: '/'
        },
        {
          text: 'Listado De Categorías',
          active: true
        }
      ],
      filter: null,
      isBusy: false,
      perPage: 8,
      currentPage: 1,
      totalRows: null
    }
  }
}
</script>
